<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">
      <div v-if="!isLoading">
        <div class="row">
          <div class="col pe-0">

            <div class="card">
              <div class="card-body">
                
                <h3>Upload data</h3>

                <template v-if="isDone">
                  <p>Your data has been uploaded successfully.</p>
                  <p>Our platform will clean your data and create a structured format.</p>
                  <p>Once the data is ready, you will receive an email notification.</p>
                </template>
                
                <template v-else>

                  <p>Please provide the most up to date and robust data set as the quality of your results will depend on quality of your data.</p>
                  <p>“Garbage in, garbage out”.</p>
                  <p>Our platform will clean your data and create a structured format.</p>

                  <form @submit.prevent="submit">
                    <div class="mb-3">
                      <label for="file" class="form-label">Files</label>
                      <input type="file" class="form-control" id="file" multiple v-on:change="onFileChange" />
                    </div>

                    <button type="submit" class="btn btn-primary">Upload</button>
                  </form>
                </template>

                <div class="py-4">
                  &nbsp;
                </div>

              </div>
            </div>
          </div> <!-- end col -->
        </div>

      </div>
      <div v-else class="text-center">
        <loader></loader>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'startup',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      startup: null,
      usage: null,
      error: null,
      isLoading: false,
      isDone: false,
      form: {
        files: [],
      }
    }
  },

  computed: {
    ...mapState({
      current: state => state.startups.current
    })
  },
  
  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    load() {},

    onFileChange(e) {
      this.form.files = e.target.files
    },

    submit() {
      this.isLoading = true
      this.error = null

      let formData = new FormData()
      for (let i = 0; i < this.form.files.length; i++) {
        formData.append('file_upload', this.form.files[i])
      }

      this.$store.dispatch('UPLOAD_INVESTOR_DATA', formData)
        .then(() => {
          this.isLoading = false
          this.isDone = true
          this.$store.dispatch('LOAD_SITE_COMPANY')
          this.$store.commit('SET_HAS_UPLOADED_FILE', true)
          this.$router.push({name: 'startups_selector'})
        })
        .catch((error) => {
          this.isLoading = false
          this.error = error
        })
    }
  }
}
</script>
